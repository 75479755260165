<ng-container *ngTemplateOutlet="
    participantEntry;
    context: {
      participants: participants
    }
  "></ng-container>
<ng-container *ngIf="!readOnly">
  <ng-container
    *ngTemplateOutlet="addMenu; context: {parent: null, content: mainAddButton, types: allowedTypes ?? ['lawyers', 'clients']}"></ng-container>
</ng-container>

<ng-template #mainAddButton>
  <button mat-button [attr.aria-label]="'common.button.addParticipant' | translate">
    <mat-icon aria-hidden="true">add</mat-icon>
    <span>{{ "common.button.addParticipant" | translate }}</span>
  </button>
</ng-template>

<ng-template #addMenu let-parent="parent" let-content="content" let-types="types">
  <app-participant-select-menu [alreadyAssigned]="[]" [types]="types" (selected)="addParticipant($event, parent)"
    *ngIf="isLawyer" class="new-participant-menu" [attr.aria-label]="'aria.label.participantSelectionMenu' | translate">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-participant-select-menu>
</ng-template>



<ng-template #participantEntry let-participants="participants">
  <div class="participant" *ngFor="let participant of participants"
    [userPeek]="isLawyer && !readOnly ? participant : null" (click)="viewParticipant(participant)" tabindex="0"
    (keydown.enter)="viewParticipant(participant)" role="button"
    [attr.aria-label]="('View participant' | translate) + ' ' + participant?.first_name + ' ' + participant?.last_name">
    <div class="participant-badge">
      <div class="profile-picture">
        <mat-icon *ngIf="!participant.profile_picture" aria-hidden="true">person</mat-icon>
        <img *ngIf="!!participant.profile_picture" [src]="API_URL +
        '/file/' +
        participant.profile_picture +
        '?realm=' +
        realm"
          [alt]="('aria.label.profilePictureOf' | translate) + ' ' + participant?.first_name + ' ' +  participant?.last_name" />
      </div>
      <div class="name">
        <ng-container *ngIf="
            participant.first_name?.length || participant?.last_name?.length
          ">
          {{
          (participant.first_name ?? "") +
          (participant.first_name?.length && participant?.last_name?.length
          ? " "
          : "") +
          (participant.last_name ?? "")
          }}
        </ng-container>
        <ng-container *ngIf="
            !participant.first_name?.length &&
            !participant?.last_name?.length &&
            participant.mail
          ">
          {{ participant.mail }}
        </ng-container>
        <ng-container *ngIf="
            !participant.first_name?.length &&
            !participant?.last_name?.length &&
            !participant.mail
          ">
          Gast
        </ng-container>
      </div>
      <div class="role" *ngIf="(!isLawyer || readOnly) && !hideRole">
        {{ "common.assigned." + participant.role | translate }}
      </div>
      <app-search-menu [entries]="participant.source === 'client' ? availableClientRoles : availableLawyerRoles"
        (entrySelected)="changeParticipantRole(
                participant,
                $event.value
              )"
        [attr.aria-label]="('aria.label.changeRoleFor' | translate) + ' ' + participant.first_name + ' ' + participant?.last_name">
        <button class="role" *ngIf="isLawyer && !readOnly && !hideRole" (click)="$event.stopPropagation();"
          (keydown.enter)="$event.stopPropagation()"
          [attr.aria-label]="('aria.label.participantRole' | translate) + ': '  +('common.assigned.' + participant.role | translate)">
          {{ "common.assigned." + participant.role | translate }}
        </button>
      </app-search-menu>
      <button mat-icon-button *ngIf="isLawyer && !readOnly"
        (click)="$event.stopPropagation();removeParticipant(participant.id, participant.source)"
        (keydown.enter)="$event.stopPropagation()" class="delete-button"
        [attr.aria-label]="('aria.label.removeParticipant' | translate) + ' ' + participant.first_name + ' ' + participant?.last_name">
        <mat-icon aria-hidden="true">delete</mat-icon>
      </button>
      <div class="add-sub-participant" (click)="$event.stopPropagation()" *ngIf="!readOnly">
        <ng-container
          *ngTemplateOutlet="addMenu; context: {parent: participant, content: addSubPartButton, types: [participant.source + 's']}"></ng-container>
      </div>
    </div>
    <div class="sub-participants" *ngIf="participant.children?.length">
      <ng-container *ngTemplateOutlet="
          participantEntry;
          context: {
            participants: participant.children
          }
        "></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #addSubPartButton>
  <button class="add-sub-participant-button" [matTooltip]="'common.button.addSubParticipant' | translate"
    [attr.aria-label]="'common.button.addSubParticipant' | translate">
    <mat-icon aria-hidden="true">add</mat-icon>
  </button>
</ng-template>