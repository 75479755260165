import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class AdvowareAuthInterceptor implements HttpInterceptor {
  // Auth error keywords to check in response error messages
  private authErrorKeywords = [
    'wrong credentials',
    'login failed',
    'authentication failed',
    'could not authenticate',
    'user is not yet registered',
    'wrong advoware credentials',
    "authentication error"
  ];

  constructor() {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Only intercept Advoware addon API calls
    if (!this.isAdvowareRequest(request.url)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      tap((event) => {
        // Only process response events
        if (!(event instanceof HttpResponse)) {
          return;
        }

        const response = event.body;

        // Check if response contains an error that indicates authentication issues
        if (response && response.error && this.isAuthError(response.error)) {
          // Redirect to password reset page
          this.redirectToPasswordReset();
        }
      })
    );
  }

  private isAdvowareRequest(url: string): boolean {
    return url.includes('/addons/') && url.includes('/advoware/');
  }

  private isAuthError(errorMsg: string): boolean {
    if (!errorMsg) return false;
    const lowerCaseError = errorMsg.toLowerCase();

    return this.authErrorKeywords.some(keyword => lowerCaseError.includes(keyword));
  }

  private extractRealm(): string | null {
    const match = window.location.href.match(/\/([^\/]+)\/intern\//);
    if (match && match[1]) {
      return match[1];
    }
    return null;
  }

  private redirectToPasswordReset(): void {
    const realm = this.extractRealm();
    if (!realm) {
      console.error('Realm not found in URL');
      return;
    }

    // Encode the redirectUrl to safely include it in the URL
    const encodedRedirectUrl = encodeURIComponent(window.location.pathname || `/${realm}/intern`);

    // Force a complete page refresh with all parameters in the URL
    window.location.href = `/${realm}/addon/advoware/password-reset/?redirectUrl=${encodedRedirectUrl}`;
  }
}
